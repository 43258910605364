import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import Banner from "../components/Banner"
import Testimonials from "../components/Testimonials"
import PracticeAreas from "../components/PracticeAreas"
import Contact from "../components/Contact"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Banner />
    <Testimonials />
    <PracticeAreas />
    <Contact />
  </Layout>
)

export default IndexPage