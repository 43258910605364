import styled from "styled-components"
import media from "styled-media-query"

export const TestimonialsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 4rem auto;
  padding: 1rem 2rem;

  .slick-slider {
    display: flex;
    justify-content: center;

    .slick-prev{
      left: -1.5rem;

      &::before{
        color: var(--gold);
      }
    }

    .slick-next{
      right: -1.5rem;

      &::before{
        color: var(--gold);
      }
    }

    .slick-track {
      display: flex;
      justify-content: center;

      .slick-slide {
        display: flex;
        justify-content: center;
      }
    }
  }



  ${media.lessThan("large")`
  `}
`

export const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 800;
  color: var(--blue);
  text-transform: uppercase;
  text-align: center;
`