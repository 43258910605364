import React from "react"
import PropTypes from 'prop-types'

import GlobalStyles from "../../styles/global"

import Navbar from "../Navbar"
import Copyright from "../Copyright"

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Navbar />
    <main>{children}</main>
    <Copyright />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout