import React from "react"
// import {Law} from "@styled-icons/octicons/Law"

import * as S from "./style"

const Area = ({children, title}) => (
  <S.AreaWrapper>
    <S.IconWrapper>
      {children}
    </S.IconWrapper>
    <S.Title>{title}</S.Title>
  </S.AreaWrapper>
)

export default Area