import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./style"

const Navbar = () => {
  const { avatarImage } = useStaticQuery(
    graphql`
      query {
        avatarImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <header>
      <S.HeaderWrapper>
        <S.LogoWrapper>
          <S.Logo fluid={avatarImage.childImageSharp.fluid} />
        </S.LogoWrapper>
      </S.HeaderWrapper>
    </header>
  )
}

export default Navbar
