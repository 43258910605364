import React from "react"
import {EmailOutline as EmailIcon} from "@styled-icons/evaicons-outline/EmailOutline"
import {Phone as PhoneIcon} from "@styled-icons/boxicons-solid/Phone"
import {Map as MapIcon} from "@styled-icons/boxicons-solid/Map"
import {Law as LawIcon} from "@styled-icons/octicons/Law"

import Map from "../Map"

import * as S from "./style"

const Contact = () => (
  <S.ContactWrapper>
    <S.ContactContainer>

      <S.MapWrapper>
        <S.Title>Localização</S.Title>
        <Map />
      </S.MapWrapper>

      <S.InfosContainer>
        <S.Title>Fale Conosco</S.Title>

        <S.InfoWrapper>
          <S.IconWrapper>
            <MapIcon />
          </S.IconWrapper>
          <S.Info>
            Rua Araújo Figueiredo, 119, sala 104, Centro, Florianópolis/SC, CEP 88.010-520
          </S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <EmailIcon />
          </S.IconWrapper>
          <S.Info>
            felipetorrens@gmail.com
          </S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
            <PhoneIcon />
          </S.IconWrapper>
          <S.Info>
            (48) 98825-5557
          </S.Info>
        </S.InfoWrapper>

        <S.InfoWrapper>
          <S.IconWrapper>
          <LawIcon />
          </S.IconWrapper>
          <S.Info>
            OAB/SC nº 19.261
          </S.Info>
        </S.InfoWrapper>

      </S.InfosContainer>
    </S.ContactContainer>
  </S.ContactWrapper>
)

export default Contact
