export const quotes = [
  {
    client: "Markian Kalinoski",
    quote:
      "Tenho confiança total no Dr. Felipe. Eficiente nos processos, sempre atencioso e aberto a conversar sem o intermédio de marcação de horário com secretárias. Sempre à disposição para qualquer esclarecimento. Mantém contato direto com seus clientes. Todas as minhas causas e as causas da minha família são enviadas ao Dr. Felipe, sem pensar duas vezes.",
  },
  {
    client: "Tiago Ribeiro",
    quote:
      "Conheci o Dr. Felipe há alguns anos depois de uma experiência insatisfatória com outro profissional. Desde o primeiro contato me passou a confiança que precisava para seguir em frente num difícil processo de erro médico que felizmente obtivemos vitória graças à sua dedicação e competência.",
  },
  {
    client: "Regina Botelho",
    quote:
      "Em 2016 precisei de ajuda de um advogado e, por indicação de um amigo e minha irmã, conheci o Doutor Felipe. Só tenho que agradecer pelo altíssimo profissionalismo e competência. Estou muito satisfeita e feliz com seus serviços prestados!",
  }
]