import styled from "styled-components"
import media from "styled-media-query"
import BackgroundImage from "gatsby-background-image"

export const BannerImage = styled(BackgroundImage)`
  min-height: 40vw;
  width: 100%;
  background-position: center 0%;
  display: flex;
  align-items: center;

  ${media.lessThan("medium")`
    min-height: 53.25vw;
  `}

  ${media.lessThan("small")`
    min-height: 66.5vw;
  `}
`

export const BannerWrapper = styled.div`
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const TextsWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.lessThan("large")`
    width: 50%;
    margin: 0 0 0 3rem;
  `}

  ${media.lessThan("medium")`
    margin: 0 0 0 1.5rem;
  `}

  ${media.lessThan("small")`
    width: 60%;
    margin: 0;
  `}
`

export const Title = styled.h1`
  font-size: 2.3rem;
  font-weight: 800;
  color: var(--lightBlue);
  text-shadow: 0.2rem 0.2rem 1rem var(--gold);

  ${media.lessThan("large")`
    font-size: 1.8rem;
  `}

  ${media.lessThan("medium")`
    font-size: 1.2rem;
  `}

  ${media.lessThan("small")`
    font-size: .95rem;
    text-shadow: 0 0 .5rem var(--white);
  `}
`

export const Text = styled.p`
  margin-top: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--white);
  text-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0,0.8);

  ${media.lessThan("large")`
    margin-top: 1rem;
    font-size: 1.2rem;
  `}

  ${media.lessThan("medium")`
    margin-top: 0.75rem;
    font-size: 1rem;
  `}

  ${media.lessThan("small")`
    margin-top: 0.5rem;
    font-size: .7rem;
  `}
`

export const Button = styled.a`
  display: flex;
  align-items: center;  
  margin-top: 2rem;
  padding: 1.25rem 4rem;
  border: none;
  box-shadow: 0.25rem 0.25rem 1rem rgba(0, 0, 0,0.8);
  background: #34af23;
  font-size: 1rem;
  color: var(--white);
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .5s;

  &:hover{
    transform: scale(1.1)
  }

  ${media.lessThan("large")`
    margin-top: 1.5rem;
    padding: 0.75rem 3rem;
    font-size: 0.85rem;
  `}

  ${media.lessThan("medium")`
    margin-top: 1.25rem;
  `}

  ${media.lessThan("small")`
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    font-size: 0.7rem;
  `}
`

export const IconWrapper = styled.div`
  width: 1rem;
  height: 1.1rem;
  margin-right: .25rem;
`