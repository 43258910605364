import styled from "styled-components"
import media from "styled-media-query"

export const ContactWrapper = styled.section`
  padding: 4rem;
  background: var(--gray);

  ${media.lessThan("medium")`
    padding: 4rem 1rem;
  `}
`
export const ContactContainer = styled.div`
  display: flex;
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem;

  ${media.lessThan("medium")`
    flex-direction: column-reverse;
  `}
`

export const MapWrapper = styled.div`
  width: 50%;
  padding-right: 2rem;

  ${media.lessThan("medium")`
    width: 100%;
    padding-right: 0;
  `}
`

export const Title = styled.h3`
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--blue);
  text-transform: uppercase;

  ${media.lessThan("small")`
    font-size: 1.5rem;
  `}
`

export const InfosContainer = styled.div`
  width: 50%;
  padding-left: 1rem;

  ${media.lessThan("medium")`
    width: 100%;
    margin-bottom: 3rem;
    padding-left: 0;
  `}
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-conten: flex-start;
  align-items: center;  
  margin-bottom: 1rem;
`

export const IconWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  color: var(--gold);

  ${media.lessThan("small")`
    width: 1.25rem;
    height: 1.25rem;
  `}
`

export const Info = styled.p`
  width: calc(100% - 2rem);
  font-size: 1rem;
  color: var(--blue);
  line-height: 1.2rem;

  ${media.lessThan("small")`
    font-size: .8rem;
  `}
`