import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const HeaderWrapper = styled.header`
  margin: 0 auto;
  padding: 1rem;
  border-bottom: 1px solid var(--blue);
`
export const LogoWrapper = styled.div`
  width: 7rem;
  margin: auto;

  ${media.lessThan("large")`
    width: 5rem;
  `}
`

export const Logo = styled(Img)`
  width: 100%;
`