import React from "react"
import {AirplanemodeActive as Airplane} from "@styled-icons/material/AirplanemodeActive"
import {AttachMoney as Money} from "@styled-icons/material-rounded/AttachMoney"
import {MedicalServices as Medical} from "@styled-icons/material-outlined/MedicalServices"
import {Restore} from "@styled-icons/material/Restore"
import {HandHoldingUsd as Hand} from "@styled-icons/fa-solid/HandHoldingUsd"
import {ChildFriendly as Child} from "@styled-icons/material/ChildFriendly"
import {Documents} from "@styled-icons/entypo/Documents"
import {OfficeBuilding as Office} from "@styled-icons/heroicons-outline/OfficeBuilding"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"

import Area from "../Area"

import * as S from "./style"

const PracticeAreas = () => (
  <S.PracticeAreasWrapper>
    <S.Title>Especialidades</S.Title>
    <S.PracticeAreasContainer>
      <Area title="Erro Médico">
        <Medical />
      </Area>
      <Area title="Cancelamento e Atraso de Vôos">
        <Airplane />
      </Area>
      <Area title="Nome no SPC/Serasa">
        <Money />
      </Area>
      <Area title="Indenização para Consumidores">
        <Restore />
      </Area>
      <Area title="Guarda e Alimentos">
        <Child />
      </Area>
      <Area title="Divórcios e Inventários">
        <Documents />
      </Area>
      <Area title="Cobrança (Cheque, Promissórias…)">
        <Hand />
      </Area>
      <Area title="Ações Trabalhistas">
        <Office />
      </Area>
    </S.PracticeAreasContainer>
    <S.Button 
      href="https://api.whatsapp.com/send?phone=554888255557&text=Ol%c3%a1!+Sim%2c+gostaria+de+falar+direto+com+o+Advogado+e+receber+uma+consultoria+gratuita+agora%2c+sem+compromisso."
    >
      <S.IconWrapper>
        <Whatsapp />
      </S.IconWrapper>
      Entre em Contato!
    </S.Button>
  </S.PracticeAreasWrapper>
)

export default PracticeAreas