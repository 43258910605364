import styled from "styled-components"
import media from "styled-media-query"

export const AreaWrapper = styled.div`
  width: 180px;
  margin: 0.75rem;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: var(--darkBackground);

  ${media.lessThan("small")`
    width: 120px;
    margin: 0.5rem;
  `}
`

export const IconWrapper = styled.div`
  width: 3rem;
  height: 3rem;
  margin-bottom: 0.5rem;
  color: var(--gold);

  ${media.lessThan("small")`
    width: 2rem;
    height: 2rem;
  `}
`

export const Title = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: var(--gold);
  text-align: center;
  margin: 0;

  ${media.lessThan("small")`
    font-size: .7rem;
  `}
`