import React from "react"

import * as S from "./style"

const Quote = ({ name, text }) => (
  <S.QuoteWrapper>
    <S.Quote>{text}</S.Quote>
    <S.Name>{name}</S.Name>
  </S.QuoteWrapper>
)

export default Quote