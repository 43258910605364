import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"

import * as S from "./style"

const Banner = () => {
  const { bannerImage } = useStaticQuery(
    graphql`
      query {
        bannerImage: file(relativePath: { eq: "banner.JPG" }) {
          childImageSharp {
            fluid(maxWidth: 4288) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <S.BannerImage
      Tag="section"
      fluid={bannerImage.childImageSharp.fluid}
      fadeIn
    >
      <S.BannerWrapper>
        <S.TextsWrapper>
          <S.Title>Advogado com mais de 15 anos de experiência com consultas e ajuizamento de ações.</S.Title>
          <S.Text>Atuação sempre focada na necessidade do cliente e na resolução total dos seus problemas.</S.Text>
          <S.Text>Entre em contato por WhatsApp clicando no botão abaixo e eu vou ajudar você!</S.Text>
          <S.Button 
            href="https://api.whatsapp.com/send?phone=554888255557&text=Ol%c3%a1!+Sim%2c+gostaria+de+falar+direto+com+o+Advogado+e+receber+uma+consultoria+gratuita+agora%2c+sem+compromisso."
          >
            <S.IconWrapper>
              <Whatsapp />
            </S.IconWrapper>
            Entre em Contato!
          </S.Button>
        </S.TextsWrapper>
      </S.BannerWrapper>
    </S.BannerImage>
  )
}

export default Banner
