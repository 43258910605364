import styled from "styled-components"
import media from "styled-media-query"

export const Footer = styled.footer`
  background: #0e2c47;
`

export const Copyright = styled.p`
  margin: 0 auto;
  padding: 1rem;
  font-size: 1rem;
  color: #FFF;
  text-align: center;

  ${media.lessThan("medium")`
    font-size: .8rem;
  `}
`