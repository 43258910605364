import React from "react"

import * as S from "./style"

const Copyright = () => (
  <S.Footer>
    <S.Copyright>
      Copyright © {new Date().getFullYear()} - Torrens & Fabre
    </S.Copyright>
  </S.Footer>
)

export default Copyright
