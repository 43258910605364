import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./style"

const Map = () => {
  const { map } = useStaticQuery(
    graphql`
      query {
        map: file(relativePath: { eq: "map.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )
  
  return (
    <S.MapLink href="https://goo.gl/maps/pP11uCEzgU5Z3JAY6" target="_blank">
      <S.MapImage fluid={map.childImageSharp.fluid} />
    </S.MapLink>
  )
}

export default Map